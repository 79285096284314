import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CDropdownHeader,
  CDropdownDivider,
  CBadge,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CForm,
  CRow,
  CCol,
  CFormInput,
  CFormTextarea
} from "@coreui/react"
import moment from 'moment'

import axios from "../../axios"
import Alert from '../../utils/alert'

const AppNotification = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const isAdmin = ["SUPER_ADMIN", "ADMIN"].includes(user?.role?.type)

  const UserNotificationCount = useSelector((state) => state.UserNotificationCount)
  const UserNotificationList = useSelector((state) => state.UserNotificationList)

  const minSearchTextLength = 2
  const [ShowSearch, setShowSearch] = useState(false)
  const [SearchText, setSearchText] = useState('')
  const [ShowSearchResult, setShowSearchResult] = useState(false)
  const [FilteredUserNotificationList, setFilteredUserNotificationList] = useState(UserNotificationList)

  const [Loader, setLoader] = useState(false)

  const [ShowAddNotifictionModal, setShowAddNotifictionModal] = useState(false)
  const [validated, setValidated] = useState(false)
  const [Title, setTitle] = useState('')
  const [Description, setDescription] = useState('')

  useEffect(() => {
    getNotificationList()
  }, [])

  const getNotificationList = () => {
    dispatch({ type: 'set', loader: true })
    axios.get('/user/notification/get')
      .then(res => {
        dispatch({ type: 'set', loader: false })
        if (res?.data?.status) {
          dispatch({ type: 'setNotifications', value: res?.data?.data })
        } else {
          Alert.show(res.data.message)
        }
      })
      .catch(err => {
        dispatch({ type: 'set', loader: false })
        Alert.show('Something went wrong!')
      })
  }

  const showAddNotificationPopup = async (status) => {
    setValidated(false)
    setTitle('')
    setDescription('')
    setShowAddNotifictionModal(status)
  }

  const addNotification = async (event) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)

    if (form.checkValidity()) {
      dispatch({ type: 'set', loader: true });
      axios.post('/admin/notification/add', {
        title: Title,
        description: Description
      }).then(res => {
        dispatch({ type: 'set', loader: false });
        if (res.data?.status) {
          setShowAddNotifictionModal(false)
          Alert.show('Your announcement has been posted.', true)
        } else {
          Alert.show(res.data.message)
        }
      }).catch(err => {
        dispatch({ type: 'set', loader: false })
        Alert.show('Failed to post your announcement!')
      })
    }
  }

  const showNotifications = () => {
    dispatch({ type: "readNotification", UserNotificationCount: 0 })
    setShowSearch(false)
    setSearchText('')
    setShowSearchResult(false)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (SearchText.length >= minSearchTextLength) {
        let FilteredUserNotificationList = UserNotificationList
          .filter((msg) => {
            return (
              msg.title.toLowerCase().includes(SearchText.toLowerCase())
              || msg.description.toLowerCase().includes(SearchText.toLowerCase())
            )
          })
        setShowSearchResult(true)
        setFilteredUserNotificationList(FilteredUserNotificationList)
      } else {
        setShowSearchResult(false)
        setFilteredUserNotificationList([])
      }
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [SearchText])

  return (
    <>
      <CDropdown variant="nav-item" autoClose="outside" className='notification-dropdown'>
        {(user?.role?.type && !isAdmin && UserNotificationCount > 0) && (
          <CBadge color="danger" position="top-end" shape="rounded-pill">
            {UserNotificationCount} <span className="visually-hidden">unread messages</span>
          </CBadge>
        )}

        <CDropdownToggle placement="bottom-end" caret={false} className="p-0">
          <svg onClick={() => showNotifications()} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4417 17.5001C11.2952 17.7526 11.0849 17.9623 10.8319 18.108C10.5788 18.2538 10.292 18.3305 10 18.3305C9.70802 18.3305 9.42116 18.2538 9.16814 18.108C8.91513 17.9623 8.70484 17.7526 8.55833 17.5001M15 6.66675C15 5.34067 14.4732 4.0689 13.5355 3.13121C12.5979 2.19353 11.3261 1.66675 10 1.66675C8.67392 1.66675 7.40215 2.19353 6.46447 3.13121C5.52678 4.0689 5 5.34067 5 6.66675C5 12.5001 2.5 14.1667 2.5 14.1667H17.5C17.5 14.1667 15 12.5001 15 6.66675Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </CDropdownToggle>
        <CDropdownMenu className="p-0" placement="bottom-end">
          <CDropdownHeader className="notification-header fw-semibold d-flex align-items-center justify-content-between p-0">
            {
              isAdmin ? (
                <CButton color="create-notification" className="text-start d-flex align-items-center gap-2" onClick={() => showAddNotificationPopup(true)}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_617_775)">
                      <path d="M9.99996 18.3333C14.6023 18.3333 18.3333 14.6023 18.3333 9.99996C18.3333 5.39759 14.6023 1.66663 9.99996 1.66663C5.39759 1.66663 1.66663 5.39759 1.66663 9.99996C1.66663 14.6023 5.39759 18.3333 9.99996 18.3333Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10 6.66663V13.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6.66663 10H13.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_617_775">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Add Announcement
                </CButton>
              )
                : (
                  <CButton color="create-notification" className="text-start d-flex align-items-center gap-2">
                    Notifications
                  </CButton>
                )
            }
            <CButton color="search-notification" onClick={() => { setShowSearch(!ShowSearch); setShowSearchResult(false); }}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </CButton>
          </CDropdownHeader>
          {
            ShowSearch && (
              <CDropdownHeader className="notification-header fw-semibold d-flex align-items-center justify-content-between p-0">
                <div className='search-input-box w-100'>
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search..."
                    value={SearchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    autoFocus={true}
                  />
                  {SearchText && <button onClick={() => setSearchText('')}> ✕ </button>}
                </div>
              </CDropdownHeader>
            )
          }

          <div className='notification-list p-3'>
            {
              Loader ? (
                <CPlaceholder as={CDropdownItem} animation="glow" xs={12} className='rounded-4 my-2'>
                  <CPlaceholder xs={12} className='rounded-2' />
                  <CPlaceholder xs={6} className='rounded-2' />
                </CPlaceholder>
              )
                : (
                  (ShowSearchResult ? FilteredUserNotificationList : UserNotificationList)?.map((notification, i) => {
                    return (
                      <div className="rounded mb-2 p-2" key={i}>
                        {i > 0 && <CDropdownDivider className="d-none" />}
                        <CDropdownItem className="flex-wrap">
                          <div className="h6 text-dark m-0 mb-1"> {notification?.title} </div>
                          <div>{notification?.description}</div>
                          <div className="d-flex w-100 justify-content-end gap-1 mt-1">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.00016 4.00016V8.00016L10.6668 9.3335M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z" stroke="#98A2B3" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {moment(notification.createdAt).format('MMM DD YYYY,  hh:mm A')}
                          </div>
                        </CDropdownItem>
                      </div>
                    )
                  })
                )
            }
            {
              (ShowSearchResult ? FilteredUserNotificationList : UserNotificationList).length === 0 && (
                <div className="rounded mb-2 p-2">
                  <CDropdownItem> No {ShowSearchResult ? 'search' : 'notification'} founds </CDropdownItem>
                </div>
              )
            }
          </div>
        </CDropdownMenu>
      </CDropdown >

      <CModal
        scrollable
        alignment="center"
        className="modal-custom"
        visible={ShowAddNotifictionModal}
        onClose={() => setShowAddNotifictionModal(false)}
      >
        <CForm
          className="row g-3 needs-validation"
          noValidate
          validated={validated}
          onSubmit={addNotification}
        >
          <CModalHeader className="px-4">
            <CModalTitle id="ScrollingLongContentExampleLabel2"> Announcement </CModalTitle>
          </CModalHeader>
          <CModalBody className="mx-4">
            <CRow>
              <CCol md={12}>
                <CFormInput
                  type="text"
                  id="title"
                  label="Title"
                  placeholder="Title"
                  feedbackInvalid="Please provide a valid value"
                  value={Title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </CCol>
              <CCol md={12} className="mt-4">
                <CFormTextarea
                  id="content"
                  label="Content"
                  placeholder="Content"
                  feedbackInvalid="Please provide a valid value"
                  value={Description}
                  style={{ width: '100%', height: '150px' }}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter className="px-4">
            <CButton color="primary" onClick={() => setShowAddNotifictionModal(false)}>
              Close
            </CButton>
            <CButton color="primary" type="submit">
              Post Announcement
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  )
}

export default React.memo(AppNotification)