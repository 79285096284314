import Swal from 'sweetalert2';

const alert = {
  show: (message, success) => {
    Swal.fire({
      icon: success ? "success" : "error",
      text: message,
      title: success ? "Success!" : "Error",
      confirmButtonText: success ? "Okay" : 'Cancel',
      confirmButtonColor: success ? "#65b66d" : '#DE3333'
    });
  }
};

export default alert;
