import React from 'react'

//user routes
const userDashboard = React.lazy(() => import('./views/pages/dashboard/UserDashboard'))
const Product = React.lazy(() => import('./views/pages/product/pricing'))

// admin routes
const AdminDashboard = React.lazy(() => import('./views/pages/dashboard/AdminDashboard/index'))
const Admin = React.lazy(() => import('./views/pages/admin/Admin'))
const Alerts = React.lazy(() => import('./views/pages/admin/Alerts'))

// common route
const Bot = React.lazy(() => import('./views/pages/bot'))
const Profile = React.lazy(() => import('./views/pages/profile/index'))
const Support = React.lazy(() => import('./views/pages/support'))

const routes = [
  { path: '/', exact: true, name: 'Home' },

  //user routes
  { path: '/dashboard/:cid', name: 'Options', element: userDashboard },
  { path: '/products', name: 'Products', element: Product },
  { path: '/support', name: 'Support', element: Support },

  // admin routes
  { path: '/admin/dashboard/:cid', name: 'Dashboard', element: AdminDashboard },
  { path: '/admin/finance', name: 'Admin', element: Admin },
  { path: '/admin/alerts', name: 'Alerts', element: Alerts },

  // common route
  { path: '/bots', name: 'Bots', element: Bot },
  { path: '/profile', name: 'Profile', element: Profile }
]

export default routes
