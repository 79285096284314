import { createStore } from 'redux'
import { messaging } from 'src/firebase'
import BellSound from './assets/audio/bell.wav';

const initialState = {
  sidebarShow: false,
  notificationCount: 0,
  showNotificationPopup: false,
  ScreenShareList: [],
  UserInterected: false,
  UserNotificationList: [],
  UserNotificationCount: 0,
  IsChatShouldScroll: true
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      if (rest.hasOwnProperty('PeerInstance')) {
        if (!rest.PeerInstance && state.PeerInstance) {
          rest['ScreenShareList'] = []
          state.PeerInstance.destroy()
        }
      }
      return { ...state, ...rest }

    case 'login':
      localStorage.setItem('token', rest.user.token)
      localStorage.setItem('user', JSON.stringify(rest.user))
      return { ...state, ...rest }

    case 'logout':
      localStorage.clear()
      messaging.deleteToken().catch(_ => { })
      return { ...state, user: null }

    case 'ScreenShare':
      let ScreenShareList = []
      switch (rest.action) {
        case 'add':
          let isFirstVideo = rest.value.kind === 'video' && state.ScreenShareList.every(l => l.kind == 'audio')
          rest.value['pin'] = isFirstVideo
          ScreenShareList = [...state.ScreenShareList, rest.value]
          return { ...state, ScreenShareList }

        case 'remove':
          let isPinnedVideoRemoved = false
          ScreenShareList = state.ScreenShareList.filter(list => {
            let shouldRemove = rest.value.participantId
              ? list.participantId === rest.value.participantId
              : list.producerId === rest.value.producerId;

            if (shouldRemove) {
              isPinnedVideoRemoved = (list.kind == 'video' && list.pin) ? true : false
              list.stream.getTracks().forEach(track => track.stop())
              return false
            } else {
              return true
            }
          })
          if (isPinnedVideoRemoved) {
            let firstVideoRef = ScreenShareList.find(l => l.kind == 'video')
            if (firstVideoRef) {
              ScreenShareList = state.ScreenShareList.map(list => {
                list['pin'] = list.producerId === firstVideoRef.producerId
                return list
              })
              return {
                ...state,
                ScreenShareList,
                pinScreen: { producerId: firstVideoRef.producerId }
              }
            }
          }
          return { ...state, ScreenShareList }

        case 'removeAll':
          state.ScreenShareList.forEach(list => {
            list.stream.getTracks().forEach(track => track.stop())
          })
          return { ...state, ScreenShareList: [] }

        case 'pin':
          ScreenShareList = state.ScreenShareList.map(list => {
            list['pin'] = list.producerId === rest.value.producerId
            return list
          })
          return {
            ...state,
            ScreenShareList,
            pinScreen: { producerId: rest.value.producerId }
          }

        case 'speaker':
          ScreenShareList = state.ScreenShareList.map(list => {
            list.muted = rest.value.muted
            return list
          })
          return { ...state, ScreenShareList: ScreenShareList }

        case 'mic':
          ScreenShareList = state.ScreenShareList.map(list => {
            if (list.participantId === rest.value.participantId) {
              list.mic = rest.value.mic
            }
            return list
          })
          return { ...state, ScreenShareList: ScreenShareList }

        case 'label':
          ScreenShareList = state.ScreenShareList.map(list => {
            if (list.producerId === rest.value.producerId) {
              list.label = rest.value.label
            }
            return list
          })
          return { ...state, ScreenShareList: ScreenShareList }

        default:
          return state
      }

    case 'setNotifications':
      let lastReadNotificationId = localStorage.getItem('lastReadNotificationId')
      let lastUserNotificationCount = 0
      if (lastReadNotificationId) {
        let refIndex = rest.value.findIndex(noti => noti._id === lastReadNotificationId)
        if (refIndex > -1) {
          lastUserNotificationCount = refIndex
        } else {
          lastUserNotificationCount = rest.value.length
        }
      } else {
        lastUserNotificationCount = rest.value.length
      }
      return { ...state, UserNotificationList: rest.value, UserNotificationCount: lastUserNotificationCount }

    case 'addNotification':
      let UserNotificationList = [rest.value, ...state.UserNotificationList]
      let UserNotificationCount = state.UserNotificationCount + 1
      if (['USER', 'TEST_USER'].includes(state?.user?.role?.type) || state?.user?.anonymousId) {
        playBell()
      }
      return { ...state, UserNotificationList, UserNotificationCount }

    case 'readNotification':
      if (state.UserNotificationList.length) {
        localStorage.setItem('lastReadNotificationId', state.UserNotificationList[0]._id)
      }
      return { ...state, UserNotificationCount: 0 }

    default:
      return state
  }
}

const playBell = () => {
  new Audio(BellSound).play()
}

const store = createStore(changeState)
export default store
