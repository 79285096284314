import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CContainer,
  CHeaderToggler,
  CHeader,
  CHeaderNav,
  CNavLink,
  CNavItem,
  CSidebarBrand
} from "@coreui/react";
import "./style.css";
import UserLogo from "src/assets/user-logo.png";
import axios from "../../axios";
import { adminNavigation, userNavigation } from "../../_nav";
import LogoutButton from "./logoutButton";
import MarketStatusIndicator from "./marketStatus";
import AppNotification from "./notification";

const AppHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const user = useSelector((state) => state.user);
  const unreadCount = useSelector((state) => state.unreadCount);

  const [navigationItems, setNavigationItems] = useState([]);
  const [userChannelsList, setUserChannelsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (user) {
      fetchChannels();
    }
  }, [user]);

  useEffect(() => {
    if (unreadCount) {
      setNavigationItems((prev) => {
        prev = prev.map((item) => {
          if (item._id) {
            let refData = unreadCount.find((ch) => ch._id == item._id);
            if (refData) {
              item["badge"] = {
                text: refData.count,
              };
            }
          }
          return item;
        });
        return prev;
      });
    }
  }, [unreadCount]);

  const fetchChannels = (url) => {
    axios
      .get("/channel/channels-list")
      .then((res) => {
        if (res.data.status) {
          let navigationItems = [];
          let channels = res?.data?.data;
          setUserChannelsList(channels);

          if (
            user?.role?.type == "SUPER_ADMIN" ||
            user?.role?.type == "ADMIN"
          ) {
            for (let index = 0; index < channels.length; index++) {
              navigationItems.push({
                component: CNavItem,
                name: channels[index].name,
                to: "/admin/dashboard/" + channels[index]._id,
              });
            }
          } else {
            for (let index = 0; index < channels.length; index++) {
              if (unreadCount === undefined) {
                navigationItems.push({
                  component: CNavItem,
                  _id: channels[index]._id,
                  name: channels[index].name,
                  // badge : {text : unreadCount[index1].count},
                  to: "/dashboard/" + channels[index]._id,
                });
              } else {
                for (let index1 = 0; index1 < unreadCount.length; index1++) {
                  if (channels[index]._id === unreadCount[index1]._id) {
                    navigationItems.push({
                      component: CNavItem,
                      _id: channels[index]._id,
                      name: channels[index].name,
                      badge: { text: unreadCount[index1].count },
                      to: "/dashboard/" + channels[index]._id,
                    });
                  }
                }
              }
            }
          }

          if (
            user?.role?.type == "SUPER_ADMIN" ||
            user?.role?.type == "ADMIN"
          ) {
            navigationItems = [...navigationItems, ...adminNavigation];
          } else {
            navigationItems = [...navigationItems, ...userNavigation];
          }

          if (user.anonymous) {
            navigationItems = navigationItems.filter(
              (item) => item.name !== "Profile"
            );
          }

          setNavigationItems(navigationItems);
        } else {
          // console.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error("Error fetching channels:", err);
      });
  };

  const navigateToHome = () => {
    for (let index = 0; index < userChannelsList.length; index++) {
      if (user?.role?.type == "SUPER_ADMIN") {
        navigate("/admin/dashboard/" + userChannelsList[index]._id, {
          replace: true,
        });
        break;
      } else {
        if (userChannelsList[index].subscribed) {
          navigate("/dashboard/" + userChannelsList[index]._id, {
            replace: true,
          });
          break;
        }
      }
    }

    if (user?.role?.type == "SUPER_ADMIN")
      navigate("/admin/dashboard/" + userChannelsList[0]._id, {
        replace: true,
      });
    else navigate("/dashboard/" + userChannelsList[0]._id, { replace: true });
  };

  const renderDropDownIcon = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 7.5C6.31417 9.31733 7.85245 10.947 9.5749 12.3476C9.82473 12.5508 10.1753 12.5508 10.4251 12.3476C12.1476 10.947 13.6858 9.31733 15 7.5"
          stroke="#949494"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !document.querySelector(".modal")
      ) {
        setIsOpen(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setIsOpen]);

  return (
    <CHeader position="sticky" className="custom_header">
      <CContainer fluid>
        <div className="d-flex logo-container m-0">
          <CHeaderNav className="d-md-flex">
            {/* <CNavItem>
              <div className="app-logo">
                <span className="username_txt">Dashboard</span>
              </div>
            </CNavItem> */}
          </CHeaderNav>
          {/* <CHeaderNav className="d-md-flex">
            <CNavItem>
              <div className="app-logo">
                <span className="welcome_txt">
                  Welcome{user?.name ? "," : ""}
                </span>
                <span className="username_txt">{user?.name}</span>
              </div>
            </CNavItem>
          </CHeaderNav> */}
        </div>

        <CSidebarBrand className=" d-md-flex">
          <button
            className="navbar-toggler sidebar-toggler hide-menu-toggle-items d-flex align-items-center gap-3 mob-side-toggle"
            type="button"
            data-toggle="sidebar-show"
            onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
          >
            <svg
              width="38"
              height="39"
              viewBox="0 0 38 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.75 9.75C4.75 8.85255 5.45889 8.125 6.33333 8.125H31.6667C32.5411 8.125 33.25 8.85255 33.25 9.75C33.25 10.6475 32.5411 11.375 31.6667 11.375H6.33333C5.45889 11.375 4.75 10.6475 4.75 9.75ZM4.75 19.5C4.75 18.6025 5.45889 17.875 6.33333 17.875H22.1667C23.0411 17.875 23.75 18.6025 23.75 19.5C23.75 20.3975 23.0411 21.125 22.1667 21.125H6.33333C5.45889 21.125 4.75 20.3975 4.75 19.5ZM4.75 29.25C4.75 28.3525 5.45889 27.625 6.33333 27.625H14.25C15.1244 27.625 15.8333 28.3525 15.8333 29.25C15.8333 30.1475 15.1244 30.875 14.25 30.875H6.33333C5.45889 30.875 4.75 30.1475 4.75 29.25Z"
                fill="black"
              />
            </svg>
          </button>
        </CSidebarBrand>

        <CHeaderNav className="d-none d-md-flex market-profile-container">
          <MarketStatusIndicator />
          {/* <div className="position-relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none"><path d="M28.9402 23.0282C28.1191 21.6139 26.8986 17.6122 26.8986 12.3856C26.8986 9.24672 25.6517 6.23643 23.4322 4.01693C21.2127 1.79744 18.2024 0.550537 15.0636 0.550537C11.9247 0.550537 8.91446 1.79744 6.69497 4.01693C4.47547 6.23643 3.22857 9.24672 3.22857 12.3856C3.22857 17.6137 2.0066 21.6139 1.18555 23.0282C0.975879 23.3878 0.864724 23.7962 0.863295 24.2125C0.861866 24.6287 0.970214 25.0379 1.17741 25.3989C1.38461 25.7599 1.68333 26.0598 2.04345 26.2685C2.40357 26.4772 2.81235 26.5873 3.22857 26.5876H9.26591C9.53896 27.9237 10.2651 29.1245 11.3216 29.9869C12.378 30.8492 13.6999 31.3203 15.0636 31.3203C16.4273 31.3203 17.7492 30.8492 18.8056 29.9869C19.8621 29.1245 20.5882 27.9237 20.8613 26.5876H26.8986C27.3147 26.587 27.7233 26.4768 28.0832 26.268C28.4431 26.0592 28.7416 25.7592 28.9487 25.3983C29.1557 25.0373 29.2639 24.6282 29.2624 24.2121C29.2609 23.796 29.1498 23.3876 28.9402 23.0282ZM15.0636 28.9546C14.3296 28.9544 13.6136 28.7266 13.0144 28.3027C12.4151 27.8789 11.9619 27.2796 11.7172 26.5876H18.4099C18.1653 27.2796 17.7121 27.8789 17.1128 28.3027C16.5136 28.7266 15.7976 28.9544 15.0636 28.9546ZM3.22857 24.2206C4.36769 22.2619 5.59557 17.7232 5.59557 12.3856C5.59557 9.87449 6.59309 7.46626 8.36869 5.69066C10.1443 3.91506 12.5525 2.91754 15.0636 2.91754C17.5747 2.91754 19.9829 3.91506 21.7585 5.69066C23.5341 7.46626 24.5316 9.87449 24.5316 12.3856C24.5316 17.7187 25.7565 22.2574 26.8986 24.2206H3.22857Z" fill="#202224" /></svg>
           
          </div> */}

          <AppNotification />

          <div className="custom-dropdown" ref={dropdownRef}>
            <div
              className="dropdown-selected"
              style={{ border: 0 }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <img
                className="profile-image"
                src={user?.profile || UserLogo}
                alt="user-logo"
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src = UserLogo
                }}
              />
              <CHeaderNav className="d-md-flex">
                <CNavItem>
                  <div className="app-logo m-0">
                    <span className="username_txt">{user?.name}</span>
                    <span className="welcome_txt">{user?.anonymousId ? 'Guest' : user?.role?.name}</span>
                  </div>
                </CNavItem>
              </CHeaderNav>
              {renderDropDownIcon()}
            </div>
            {isOpen && (
              <div className="dropdown-options">
                {navigationItems.map((data, index) => {
                  if (data?.is_dropdown) {
                    return (
                      <CNavLink
                        key={index}
                        onClick={() => setIsOpen(!isOpen)}
                        to={data?.to}
                        component={NavLink}
                        style={{ color: "#5B5B5D", padding: 0 }}
                      >
                        <div className="dropdown-option">{data?.name}</div>
                      </CNavLink>
                    );
                  }
                })}
                <LogoutButton />
              </div>
            )}
          </div>
        </CHeaderNav>
      </CContainer>
    </CHeader >
  );
};

export default AppHeader;
